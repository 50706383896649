import styled, { css } from 'styled-components';
import { media } from 'utils';

export const Input = styled.input`
  width: 100%;
  height: ${({ isTextarea }) => (isTextarea ? '150px' : '40px')};
  padding: 15px;
  border: 1px solid
    ${({ isError, theme }) =>
      isError ? theme.color.error : theme.color.silver};
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  transition: border 0.1s ease, background-color 0.1s ease;
  color: ${({ isError, theme }) =>
    isError ? theme.color.error : theme.color.gray};
  background-color: ${({ theme }) => theme.color.white}};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  font-size: 14px;

  &:hover,
  &:focus {
    outline: none;
    border-color: ${({ disabled, isError, theme }) => {
      if (isError) return theme.color.error;
      if (disabled) return theme.color.silver;

      return theme.color.dustyGray;
    }};
  }

  ${media.large(css`
    font-size: 16px;
    height: ${({ isTextarea }) => (isTextarea ? '200px' : '50px')};
    padding: 20px;
  `)}
`;

export const FormWrapper = styled.div``;

export const Form = styled.form`
  margin: 0;

  .submitButton {
    margin-left: auto;
  }

  .hidden-input {
    display: none;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.color.darkBlue};

  ${media.large(css`
    font-size: 18px;
    margin-bottom: 10px;
  `)}
`;

export const ErrorMessage = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: 12px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.color.error};
  color: ${({ theme }) => theme.color.white};
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 5px;
  white-space: nowrap;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    top: 0;
    left: 50%;
    background-color: ${({ theme }) => theme.color.error};
    transform: rotate(45deg) translateX(-50%);
  }
`;
