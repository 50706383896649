import styled from 'styled-components';

export const AlertWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 15px 15px 15px 50px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
  border-left: 4px solid;
  border-left-color: ${({ theme, type }) =>
    theme.color[type] || theme.color.success};

  .alert-icon {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
  }

  .close-icon {
    margin-left: 20px;
  }
`;

export const AlertMessage = styled.p`
  color: ${({ theme }) => theme.color.gray};
  font-size: 16px;
  line-height: 22px;
  font-family: Roboto;
  margin: 0;
`;
