import React from 'react';

import GoogleMapReact from 'google-map-react';

import { GOOGLE_MAP_DIRECTIONS } from 'consts';

import { GoogleMapWrapper, GoogleMarker } from './GoogleMapStyles';

export const GoogleMap = () => (
  <GoogleMapWrapper>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GATSBY_GOOGLE_API_KEY,
      }}
      defaultCenter={GOOGLE_MAP_DIRECTIONS}
      defaultZoom={16}
    >
      <GoogleMarker {...GOOGLE_MAP_DIRECTIONS} />
    </GoogleMapReact>
  </GoogleMapWrapper>
);
