import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import * as CONTACT_DETAILS from 'consts/contactDetails';

import { FluidImagePropTypes } from 'types';

import { PageSection } from 'components/common/PageSection/PageSection';
import { SecondaryHeader, ThirdHeader } from 'components/common/Typography';
import { Image } from 'components/common/Image/Image';
import { Fade } from 'components/common/Animations';

import {
  ContactDetail,
  ContactDetails,
  ContactItem,
  ContactWrapper,
  GridWrapper,
} from './ContactStyles';
import { ContactForm } from './ContactForm/ContactForm';
import { GoogleMap } from './GoogleMap/GoogleMap';

export const Contact = ({ image, title }) => (
  <>
    <Image
      alt={title}
      as={image?.childImageSharp?.fluid ? Image : 'img'}
      fluid={image?.childImageSharp?.fluid}
      src={image}
    />
    <PageSection>
      <ContactWrapper>
        <Fade>
          <SecondaryHeader isBolded isUnderlined>
            {title}
          </SecondaryHeader>
        </Fade>
        <GridWrapper>
          <Fade>
            <ContactDetails>
              <ThirdHeader isBolded>
                Szkoła Języka Angielskiego <br /> Perfect English <br />
                Karolina Karny
              </ThirdHeader>

              <ContactItem>{CONTACT_DETAILS.ADDRESS}</ContactItem>
              <ContactItem marginBottom="30px">
                NIP: {CONTACT_DETAILS.NIP_NUMBER}
              </ContactItem>

              <ContactItem isBolded>
                Email:{' '}
                <ContactDetail href={`mailto:${CONTACT_DETAILS.EMAIL_ADDRESS}`}>
                  {CONTACT_DETAILS.EMAIL_ADDRESS}
                </ContactDetail>
              </ContactItem>
              <ContactItem isBolded marginBottom="30px">
                Telefon:{' '}
                <ContactDetail href={`tel:${CONTACT_DETAILS.PHONE_NUMBER}`}>
                  {CONTACT_DETAILS.PHONE_NUMBER}
                </ContactDetail>
              </ContactItem>

              <ContactItem isBolded>Numer rachunku bankowego:</ContactItem>
              <ContactItem>Bank Millennium SA</ContactItem>
              <ContactItem marginBottom="30px">
                {CONTACT_DETAILS.BANK_ACCOUNT}
              </ContactItem>

              <ContactItem isBolded>Polub nas!</ContactItem>
              <ContactItem>
                <ContactDetail
                  href={`https://${CONTACT_DETAILS.FACEBOOK_URL}`}
                  target="_blank"
                >
                  {CONTACT_DETAILS.FACEBOOK_URL}
                </ContactDetail>
              </ContactItem>
            </ContactDetails>
          </Fade>
          <Fade>
            <ContactForm />
          </Fade>
        </GridWrapper>
      </ContactWrapper>
    </PageSection>
    <Fade>
      <GoogleMap />
    </Fade>
  </>
);

export const ContactProps = {
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
};

Contact.propTypes = ContactProps;

export const contactPageFragment = graphql`
  fragment ContactPageFragment on MarkdownRemark {
    frontmatter {
      title
      image {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
