export const initialState = {
  isFormValid: false,
  isLoading: false,
};

export const initialFormValues = {
  email: '',
  message: '',
  name: '',
  title: '',
};

export const FORM_INPUTS = [
  {
    label: 'Imię i nazwisko',
    name: 'name',
    type: 'text',
  },
  {
    label: 'Email',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Tytuł',
    name: 'title',
    type: 'text',
  },
  {
    label: 'Wiadomość',
    name: 'message',
    type: 'textarea',
  },
];

export const getInputLabel = (name) =>
  FORM_INPUTS.find((input) => input.name === name).label;
