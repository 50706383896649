import React from 'react';
import PropTypes from 'prop-types';

import errorIcon from 'images/error.svg';
import successIcon from 'images/success.svg';
import closeIcon from 'images/close.svg';

import { AlertWrapper, AlertMessage } from './AlertStyles';

export const Alert = ({ message, type }) => (
  <AlertWrapper type={type}>
    <AlertMessage>{message}</AlertMessage>
    <img
      alt="Action Icon"
      className="alert-icon"
      src={type === 'success' ? successIcon : errorIcon}
    />
    <img alt="Close" className="close-icon" src={closeIcon} />
  </AlertWrapper>
);

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
