import React from 'react';

import { toast, ToastContainer as Toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

export const ToastContainer = () => (
  <Toast
    bodyClassName="alert-body"
    className="alert-container"
    closeButton={false}
    hideProgressBar
    position={toast.POSITION.TOP_CENTER}
    toastClassName="alert-toast"
  />
);
