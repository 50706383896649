import styled, { css } from 'styled-components';
import { media } from 'utils/mediaQueries';

import logoSignUrl from 'images/logo-sign.svg';

export const GoogleMapWrapper = styled.div`
  width: 100%;
  height: 300px;

  ${media.large(css`
    height: 500px;
  `)}
`;

export const GoogleMarker = styled.div`
  position: absolute;
  transform: translate(-50%, -70%);
  width: 40px;
  height: 40px;
  background: #ffffff url(${logoSignUrl}) center no-repeat;
  border-radius: 5px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.5);
  z-index: 0;

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    top: calc(100% - 3px);
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-bottom-right-radius: 2px;
    background-color: #ffffff;
    z-index: -1;
  }
`;
