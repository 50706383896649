import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { PageMetaDataPropTypes } from 'types';

import { Contact, ContactProps } from 'components/Contact/Contact';
import { Layout } from 'components/Layout/Layout';

const ContactPage = ({
  data: {
    markdownRemark: {
      // eslint-disable-next-line react/prop-types
      frontmatter: { pageDescription, pageKeywords, pageTitle, ...rest },
    },
  },
}) => (
  <Layout
    description={pageDescription}
    keywords={pageKeywords}
    title={pageTitle}
  >
    <Contact {...rest} />
  </Layout>
);

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        ...PageMetaDataPropTypes,
        ...ContactProps,
      }),
    }),
  }).isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...PageMetaDataFragment
      ...ContactPageFragment
    }
  }
`;
