import styled, { css } from 'styled-components';
import { media } from 'utils';

export const ContactWrapper = styled.div`
  max-width: ${({ theme }) => theme.layout.contentWidth};
  width: 100%;
  margin: 0 auto;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-gap: 50px;

  ${media.large(css`
    grid-template: auto / 1fr 1fr;
  `)}
`;

export const ContactDetails = styled.div``;

export const ContactItem = styled.p`
  font-size: 16px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '7px'};
  color: ${({ isBolded, theme }) =>
    theme.color[isBolded ? 'darkBlue' : 'gray']};
  font-weight: ${({ isBolded }) => (isBolded ? 500 : 400)};

  ${media.large(css`
    font-size: 18px;
  `)}
`;

export const ContactDetail = styled.a`
  cursor: pointer;
  transition: color 0.2s ease;
  color: ${({ theme }) => theme.color.gray};
  font-weight: 400;
  text-decoration: none;
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.color.primary};

    &:after {
      width: 95%;
      background-color: ${({ theme }) => theme.color.primary};
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.gray};
    top: calc(100% + 0.5px);
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.2s ease, color 0.2s ease;
  }
`;
