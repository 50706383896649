import React, { Component } from 'react';

import { toast } from 'react-toastify';
import { Spring } from 'react-spring/renderprops';

import { formEncode } from 'utils';

import { Alert } from 'components/common/Alert/Alert';
import { ToastContainer } from 'components/common/ToastContainer/ToastContainer';
import { Button } from 'components/common/Button/Button';
import {
  ErrorMessage,
  Form,
  FormGroup,
  FormWrapper,
  Input,
  Label,
} from './ContactFormStyles';

import {
  getInputLabel,
  initialState,
  initialFormValues,
  FORM_INPUTS,
} from './contactForm.utils';

export class ContactForm extends Component {
  state = {
    ...initialFormValues,
    ...initialState,
    errors: { ...initialFormValues },
  };

  handleOnChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, () => this.validateField(name, value));

  handleOnBlur = ({ target: { name, value } }) =>
    this.validateField(name, value);

  validateField = (name, value) => {
    const { errors } = this.state;
    const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

    errors[name] = !value ? `Pole ${getInputLabel(name)} jest wymagane` : '';

    if (name === 'email') {
      errors[name] = !value
        ? 'Pole Email jest wymagane'
        : !emailRegex.test(value)
        ? 'Podaj prawidłowy adres email'
        : '';
    }

    this.setState({ errors }, this.isFormValid);
  };

  isFormValid = () => {
    const { email, errors, message, name, title } = this.state;
    const fields = { email, message, name, title };
    const isValid = Object.values(errors).every((error) => !error);
    const isFormFilled = Object.values(fields).every((field) => field);

    this.setState({ isFormValid: isValid && isFormFilled });
  };

  notify = (props) => toast(<Alert {...props} />);

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, isFormValid, message, name, title } = this.state;
    const body = { email, message, name, title };

    if (!isFormValid) return;

    this.setState({ isLoading: true });

    fetch('/', {
      body: formEncode({ 'form-name': 'contact', ...body }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    })
      .then(() => {
        this.setState({
          ...initialFormValues,
          ...initialState,
          errors: { ...initialFormValues },
        });
        this.notify({
          message: 'Formularz został wysłany poprawnie.',
          type: 'success',
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        this.notify({
          message:
            'Podczas wysyłania formularza wystąpił błąd. Spróbuj jeszcze raz.',
          type: 'error',
        });
      });
  };

  render() {
    const { errors, isFormValid, isLoading } = this.state;

    return (
      <FormWrapper>
        <Form
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="contact"
          onSubmit={this.handleSubmit}
        >
          <input className="hidden-input" name="bot-field" />
          {FORM_INPUTS.map(({ label, name, type }) => (
            <FormGroup key={label}>
              <Label isBolded>{label}</Label>
              <Input
                as={type === 'textarea' ? 'textarea' : Input}
                disabled={isLoading}
                isError={!!errors[name]}
                isTextarea={type === 'textarea'}
                name={name}
                onBlur={this.handleOnBlur}
                onChange={this.handleOnChange}
                type={type}
                value={this.state[name]}
              />
              {errors[name] && (
                <Spring
                  from={{ opacity: 0, transform: 'translate(-50%) scale(0.5)' }}
                  to={{ opacity: 1, transform: 'translate(-50%) scale(1) ' }}
                >
                  {(styles) => (
                    <ErrorMessage style={styles}>{errors[name]}</ErrorMessage>
                  )}
                </Spring>
              )}
            </FormGroup>
          ))}
          <Button
            className="submitButton"
            disabled={!isFormValid || isLoading}
            isLoading={isLoading}
            type="submit"
          >
            Wyślij
          </Button>
        </Form>
        <ToastContainer />
      </FormWrapper>
    );
  }
}
